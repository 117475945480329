@import "../../assests/styles/base.scss";

.header {

    width: 99vw - $sidebarWidth;
    height: $headerHeight;
    min-height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $screenPadding;
    @media screen and (max-width : 900px) {
        width:  99vw;
      }

}
.headerUser {
    display: flex;
    align-items: center;
    
}
.headerUserMenu {
    margin-left: .5rem;
    display: flex;
    align-items: center;
}
.userImage, .userName ,.userArrow {
    margin-right: .5rem;
    display: flex;
    align-items: center;
}
.userImage {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: grey;
    font-size: 40px !important;
    color: white;
    overflow: hidden;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.headerTitle {
    text-transform: capitalize;
    font-weight: 700;
    font-size: 20px;
}