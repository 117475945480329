@import "../../../assests/styles/base.scss";
.accountInformation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.branchLogin {
    cursor: pointer;
}
iframe {
    display: none;
}
.sideSearchh {
    position: relative;
    height: 40px;
    min-height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    margin: .2rem .2rem;
    font-weight: $bold;
    font-size: 14px;
    font-family: $code;
    border-left: 3px solid transparent;
    border-radius: 8px;

    &:hover {
        background: rgba(171, 176, 185, 0.2);
border-radius: 8px;
    }
}

.sideName {
/* Text 3/bold */
text-transform: capitalize;
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 150%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
text-align: center;

/* White */

color: #FFFFFF;
margin: 2px 0px;
}
.sideSearch {
    background: rgba(171, 176, 185, 0.2);
    border-radius: 8px;
}
.sideRole {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 140%;
    /* identical to box height, or 14px */
    
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Grey/light */
    
    color: #ABB0B9;
    text-transform: capitalize;
}
.sideLine {
background: #ABB0B9;
opacity: 0.5;
border-radius: 14px;
height: 1px;
width: 100%;
margin: 1rem 0;
}
.sidebarContainer {
    height: 100vh;
    width: $sidebarWidth;
    background-color: #5E6A7A;
    color: white;
    position: absolute;
    left: 0px;
    z-index: 3;
    overflow: hidden;
    .activeSideBarX {
        display: none;
    }
    @media screen and (max-width : 900px) {
        width:  $sidebarWidth-mobile;
      }
      @media screen and (max-width : 1150px) {
       .routeName {
           display: none;
       }
       .routeContainer {
        justify-content: center;
       }
       .routeIcon {
           font-size: 1rem;
           transition: all .5s;
       }
      }
}
.mobileSideBar {
    .activeSideBarX {
        display: none;
    }
    @media screen and (max-width: 900px) {
    width: 100%;
    .routeName {
        display: block;
    }
    .routeContainer {
        justify-content: flex-start;
       }
       .activeSideBarX {
        display: block;
        position: fixed;
        right: 10px;
        top: 10px;
        font-size: 1.7rem !important;

       }
    }
}
.routeName {
    margin-top: .2rem;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 140%;
/* or 20px */

display: flex;
align-items: center;

/* White */

color: #FFFFFF;
}
.sidebarChildren {
    height: 100%;
    padding: 0 1rem;
}
.sidebarLogo {
    justify-content: flex-end;
    height: 16%;
    min-height: 70px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    
}
.loginRightContainer {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
   position: relative;
    img {
         position: absolute;
    top: 100px;
    }
}
.addWorkspaceButton {
    color: #FF7EA1;
}
.activeChild{
display: flex !important;
border:2px solid blue ;
}
.disableActivChild{
display: none !important;
}
.sidebarContent {
    height: 55%;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.routeContainer {
    position: relative;
    height: 40px;
    min-height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    margin: .2rem .2rem;
    font-weight: $bold;
    font-size: 14px;
    font-family: $code;
    border-left: 3px solid transparent;
    border-radius: 8px;

    &:hover {
        background: rgba(171, 176, 185, 0.2);
border-radius: 8px;
    }
}
.activeRoute {
    background: #6064D2;
    border-radius: 8px;
    &:hover {
        background: #6064D2;
        border-radius: 8px;
    }
}
.logoutH {
    &:hover {
        background: linear-gradient(0deg, rgba(94, 106, 122, 0.7), rgba(94, 106, 122, 0.7)), #ABB0B9 !important;
    }
}
.routeIcon {
    &.forceRight {
        position: absolute;
        right: 0px;
    }
    margin-right: .8rem;
    font-weight: 300 !important ; 
    width: 24px !important;
    transition: all .5s;
}