@import "../../../assests/styles/base.scss";
.audit-status {
  background: #FEC400;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  width: 79px;
  height: 22px;
  }
  .cardHeaderIcon {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
  }
  
  .auditAreasContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 72vh;
      overflow: auto;
  
  @media screen and (max-width: 900px) {
      overflow: visible;
  }    
      &::-webkit-scrollbar {
          width: 0px !important;
        }}
  
  .collapseArrow {
      cursor: pointer;
      transition: transform .3s ease-in-out;  
  }
  .collapseActive {
      transform:rotate(-180deg);
  }
  
  .viewActive {
      color: white;
  }
  .viewContainer {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 .2rem;
      color: #3D80C2;
  
  }
  .viewActiveContainer {
      background: #3D80C2 !important;
      border-radius: 4px !important;
      color: white;
  }
  .viewActive {
      font-size: 1.7rem !important;
  }
  
  .areaContainerOff {
      max-height: 0px;
      overflow: hidden;
  
  }
  .areaContainerOn {
      max-height: 100%;
      overflow: hidden;
  }
  
  
  .auditFiltersContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  .filterAuditInput {
      width: 216px;
      height: 36px;
      margin: 0 .5rem;
      padding: 10px 8px !important;
      border: 1px solid #C5C5C5 !important;
      border-radius: 4px !important;
      font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  background-color: white !important;
  
  /* grayscale / black */
  
  color: #252733;
  
  opacity: 0.6;
  }
  .auditsCardContainer {
      
      height: 100%;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 10px 20px;
  }
  .auditCardContainer {
      
      width: 23.8%;
      padding-bottom: 1rem;
      height: 261px;
      @media screen and (max-width: 617px) {
          width: 100%;
          
      }
  }
  .auditCard {
      
      height: 100%;
      background: #FFFFFF;
      border: 1px solid #DFE0EB;
      border-radius: 8px;
      padding: 5px 16px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      
  }
  .auditCardHeader, .auditCardFooter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      
      width: 100%;
      height: 22%;
  }
  .auditCardContent {
      display: flex;
      
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
      height: 58%;
      margin-top: .6rem;
  }
  .auditCardFooter {
      box-shadow: inset 0px 1px 0px #DFE0EB;
  }
  .auditInformation {
      width: 50%;
  }
  .cardHeaderInfo, .auditCount {
      display: flex;
      align-items: center;
  }
  .clientInfoName {
      font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */
  
  letter-spacing: 0.5px;
  
  /* grayscale / black */
  
  color: #252733;
  }
  .clientInfoAddress {
      font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  
  /* grayscale / black */
  
  color: #252733;
  }
  .typeHeaderAudit {
      font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  
  /* grayscale / black */
  
  color: #252733;
  
  opacity: 0.6;
  } 
  .typeContentAudit {
      font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  
  letter-spacing: 0.5px;
  
  /* grayscale / black */
  
  color: #252733;
  }
  .auditCountNumber {
      margin: 0 .5rem;
      font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */
  
  letter-spacing: 0.5px;
  
  /* grayscale / black */
  
  color: #252733;
  
  opacity: 0.6;
  }
  .cardStatus {
      display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  
  position: static;
  width: 76px;
  height: 22px;
  left: 110px;
  top: 7.5px;
  
  background: #FEC400;
  border-radius: 100px;
  margin: 0px 16px;
  }
  
  
.userBottom {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width : 900px) {
      flex-direction: column-reverse;
  }

}
.userInfo {
  width: 22%;
  min-width: 240px;
  @media screen and (max-width : 900px) {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }
}


.userSquare {
  width: 220px;
  margin-bottom: 1rem;
  border-radius: 8px;
  background-color: white;
  padding: 27px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width : 900px) {
      margin: 1rem;
  }
}
.userSquareHeader {
  font-size: 16px;
  font-weight: bold;
  color: #252733;
  text-transform: capitalize;
}
.userSquareMiddle, .userSquareBottom {
  display: flex;
  flex-direction: column;
  height: 30px;
  margin: 1rem 0;
}
.squareHeader {
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.5px;
color: #25273380;
text-transform: capitalize;
margin-bottom: .5rem;


}
.squareContent {
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 19px;
text-transform: capitalize;
/* identical to box height */

letter-spacing: 0.5px;
}

.expiryDate {
  padding: 2px 5px;
  border-radius: 100px;
  color: white;
}

.activeData {
  background-color: #29CC97;
}
.expiredDate {
  background-color: rgb(240, 73, 44);
}

.userActions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightActions {
  display: flex;
  align-items: center;
}
.userActionsButtons {
  width: auto !important;
  padding: 0 .8rem;
}
.userReports {
  width: 100%;    
  background-color: white;
  border-radius: 8px;
  margin-bottom: 1rem;
  min-height: 300px;
  height: 100%;
  padding: 16px 32px;
}


.auditReports {
  width: 100%;    
  background-color: rgb(226, 243, 240);
  border-radius: 8px;
  margin-bottom: 1rem;
  max-height: 45px;
  transition: max-height  .5s cubic-bezier(0, 1, 0, 1); 
  padding: 12px 32px;
}
.auditExpanded {
  max-height: 10000px;
  transition: max-height .5s ease-in-out;
  
}

.reportsContainer {
  margin: 1rem 0;
}


.addCampaignClass {
  position: relative;
  -webkit-box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  -moz-box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  box-shadow: -1px 2px 20px 4px rgba(99, 99, 99, 1);
  height: 60%;
  border-radius: 10px;
  min-height: 500px;
  width: 25vw;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(244, 239, 243);
  overflow: auto;
}

.campaignContainer {

  height: 100%;
  width: 100%;
  padding: 2rem;

}


$toggle-background-color-on: #3D80C2;
$toggle-background-color-off: #eeeeeecb
;
$toggle-control-color: #DEDEDE;
$toggle-width: 45px;
$toggle-height: 22px;
$toggle-gutter: .2px;
$toggle-radius: 50%;
$toggle-control-speed: .15s;
$toggle-control-ease: ease-in;

// These are our computed variables
// change at your own risk.
$toggle-radius: calc($toggle-height / 2);
$toggle-control-size: calc($toggle-height - calc($toggle-height / 2));

.toggle-control {

    display: flex;
    justify-content: center;
    align-items: center;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checked ~ .control {
    background-color: $toggle-background-color-on;
    
    &:after {
      left: $toggle-width - $toggle-control-size - $toggle-gutter;
    }
  }

  .control {
    position: relative;
    top: 0;
    left: 0;
    height: $toggle-height;
    width: $toggle-width;
    border-radius: $toggle-radius;
    background-color: $toggle-background-color-off;
    transition: background-color $toggle-control-speed $toggle-control-ease;

    &:after {
      content: "";
      position: absolute;
      left: $toggle-gutter;
      top: $toggle-gutter;
      width: $toggle-control-size;
      height: $toggle-control-size;
      border-radius: $toggle-radius;
      background: $toggle-control-color;
      transition: left $toggle-control-speed $toggle-control-ease;
    }
  }
}

.adminDetailsContainer {
  width: 99vw - $sidebarWidth;
  padding: $screenPadding;
  height: 100%;
  overflow: auto;
  @media screen and (max-width : 900px) {
    width:  99vw;
  }}
.adminDetails {
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.detailsContent {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin-bottom: 1rem;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 90px;
  @media screen and (max-width : 900px) {
    position: relative;
    height: 97%;
    flex-direction: column;
    justify-content: center !important;
    min-height: 400px;
    .selectedAccount img {
      margin: 0px !important;
    } 
    .detailsAvatar {
      text-align: center;
      flex-wrap: wrap;
    }
    .contentLeft {
      flex-direction: column !important;
      justify-content: center !important;
      align-items: center !important;
      .adminAvatar {
        width: 10rem !important;
        height: 10rem !important;
        cursor: pointer;
      }

    }
  }
}

.adminPermissions {
  width: 100%;
  min-height: 30%;
  display: flex;
  flex-direction: column;
  
}
.permissionsHeader {
  margin: 0 0 2rem 0;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  color: #252733;

} 
.persmissionsContent {
  display: flex;
  flex-wrap: wrap;
} 
.permission {
  margin: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;

} 
.permissionToggle {
  margin-right: .5rem;

} 
.permissionTilte {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  color: #252733;
}
.adminAvatar {
    object-fit: cover;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.selectedAccount img {
  width:80px !important; 
  height:80px !important;
  margin-right:29px;
}

.contentLeft {
  display: flex;
  align-items: flex-start;
}
.detailsName div {
  font-size: 24px;
  font-weight: 700;
  color: #252733;
}
.userType {
  color: #ffffff;
  background-color: #3D80C2;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 27px;
  width: 115px;
  font-size: 11px;
  margin-top: 5px;
  margin-left: .6rem;
  
}
.detailsEmail div,.detailsNumber div {
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.5px;
  color: #252733;
}
.detailsEmail div, .detailsNumber div, .detailsName div, .userType div, .userSquare .borderHover{
  padding: 2px !important;
  &:hover {
    border: 0px;
    background-color: transparent !important;
    width: auto !important;
  }
}

.contentRight {
  display: flex;
  align-items: center;
  right: 10px;
  top: 10px;
  @media screen and (max-width : 900px) {
  position: absolute;    
  }
}
.detailsSmaller div {
  font-size: 90%;
}
.detailsClients {
  padding: 1.4rem 0 0 0;
}
.detailsSmaller {
  margin: .5rem 0 0 0;
}

.dotsVContainer {
  position: relative;
}
.optionMenuContainer {
  position: absolute;
  width: 120px;
  min-height: 60px;
  z-index: 2;
  padding: 8px 0px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 0px;
}

.option {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(173, 173, 173, 0.603);
  width: 100%;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.5px;
  color: #252733;
}
.option.archive {
  color: red;
  border-bottom: 0px;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 2rem;
  height: 300px;
  justify-content: space-between;
  padding-right: 50px;
}
.right {
  border-left: 0.1px solid white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 2rem;
  height: 300px;
  justify-content: space-between;
  padding-left: 50px;

}
.emptyFeedback {
  cursor: pointer;
  color: rgb(47, 140, 177);
  &:hover {
    color: rgb(7, 84, 114);
  }
}
.addUserIcon {
    position: fixed;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    bottom: 5vh;
    right: 20px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    transition: all .5s;
    cursor: pointer;
    &:hover {
      transition: all .5s;
      transform: rotate(90deg);
    }
   }
  .goodJob {
    font-size: 30px;
    color: rgb(241, 241, 241);
    text-transform: uppercase;
  }
  .addUserI {
    color: black;
    font-size: 30px;
  }
  .addUserT {
    color: black;
    font-size: 30px;
    display: none;
    cursor: pointer;
  }