.areaContainer {
    display: flex;
    flex-direction: column;
}
.areaUpperContainer {
    display: flex;
    justify-content: space-between;
    
}
.testingDiagramHeader {
    @media screen and (max-width: 590px) { 
        display: flex;
        justify-content: center;
    }
}
.testingDiagramCard {
    @media screen and (max-width: 590px) { 
        .diagramDetails {
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
        }
        .diagramDetailsContainer {
            width: auto;
        }
        .typeHeader {
            text-align: center;
        }
        .diagramDetails {
            .typeHeader {
                text-align: left;
            }        }
    }
}

.diagramDetailsContainer {
    width: 100%;
}
.responsiveArea {
    @media screen and (max-width: 1460px) {
        flex-direction: column;
        align-items: center;
        .rightCards {
            width: 100%;
            margin: 1rem 0;
        }
        
    }

    @media screen and (max-width: 900px) {
       .areaCard {
           height: 250px;
       }
        .area-details {
            margin: 1rem .5rem;
        }
    }
    @media screen and (max-width: 737px) { 
        .areaLowerContainer {
            flex-direction: column-reverse;
        }
        .rightUpper {
            flex-direction: column;
            align-items: center; 
            .areaCard {
                width: 100% !important;
                    margin: 1rem 0 !important;
            }
        }
        .rightLower {
            .areaUpperContainer {
                flex-direction: column;
                align-items: center;
                .areaCard {
                    width: 100%;
                    margin: 1rem 0;
                }
            }
        }
    }

    
}
.areaMiddleContainer, .areaLowerContainer {
    padding: 0;
}
.rightCards {
    height: 100%;
    width: 70%;
    
    .rightLower {
        padding: .5rem 0;
    }
   .rightUpper {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 65%;
    .areaCard {
        width: 50%;
        height: 100%;
        margin:0 .5rem;
        min-width:190px
        }
}
 
}

.areaCard {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
    height: 186px;
    padding: 16px 32px;
    background: #FFFFFF;
    border: 1px solid #DFE0EB;
    box-sizing: border-box;
    border-radius: 8px;
    margin:0 .5rem;
}
.areaMiddleContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0;
}

.typeHeader {
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 16px;
letter-spacing: 0.5px;
text-transform: capitalize;

/* grayscale / black */

color: #252733;

opacity: 0.6;
margin: 7px 0px;

}
.typeContent {
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
/* identical to box height, or 150% */

letter-spacing: 0.5px;

/* grayscale / black */

color: #252733;

}
.areaLowerContainer {
    display: flex;
    justify-content: space-between;
}

.areaImages {
    height: 100%;
}
.typeContent {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.areaImage {
    width: 90px;
    height: 90px;
}
.areaLocationsContainer {
    margin: 2rem 0;
}
.locationsTable {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}
.location-label {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}