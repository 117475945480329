
.auditField {
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media screen and (max-width:900px) {
        margin-top: .7rem;
        
    }
}
.detailHeader {
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */

letter-spacing: 0.5px;

/* grayscale / black */

color: #252733;

opacity: 0.6;
}
.detailContent {
    font-style: normal;
    font-weight: 700  !important;
    font-size: 14px !important;
    line-height: 16px;
    letter-spacing: 0.5px;
    
    /* grayscale / black */
    
    color: #252733 !important;
}
.detailsAvatar  {
    padding: 0 .5rem;
}