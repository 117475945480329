@import "./assests/styles/base.scss";
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Rubik", sans-serif;
  }


input::placeholder {
  opacity: 0.3;
  color: black !important;
}


  // .bcg {
  //   background: url("https://i.ibb.co/YjJ5Zz5/ramadan1.jpg");
  //   background: url("https://i.ibb.co/RyxhQgh/ramadan2.jpg");
  //   background: url("https://i.ibb.co/nwRM1DX/ramadan3.jpg");
  //   background: url("https://i.ibb.co/Bg9WHQ3/5303747.jpg");
  //   background: url("https://i.ibb.co/BVZYCrx/cbd88193-8fd7-44cf-89b6-c66bf16782cfeewewqewqewewsfdfww.png");

  //   background-size: cover;
  // }
  .toggleMenuSideBarContainer {
    position: fixed;
    top: 0px;
    left: 0px; 
    width: 10px;
    height: 100vh;
    justify-content: flex-start;
    align-items: center;
    z-index: 3;
    display: none;
    @media screen and (max-width: 900px) {
      display: flex;
    }
  }
  .toggleMenuSideBar {
    opacity: 0;
    @media screen and (max-width: 900px) {
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: toggleSideBar;
    }
  }
  .toggleMenuSideBarIcon {
    filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
    color: white !important;
    
  }
  
  
.app {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

a {
  color: #fff;
  text-decoration: none;
}

.modal_x {
  position: fixed;
  top: 10px;
  right: 10px;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  cursor: pointer;
}

.adminContainer {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}


.slick-prev {
  left: -150px !important;
}
.slick-next {
  right: -150px !important;
}
.adminContent {
  width: 99vw - $sidebarWidth;
  height:100%;
  background-color: rgba(12, 12, 12, 0.862);
  // background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.881),);

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: $screenPadding;
  padding-top: 50px;
  overflow: auto;
  &.column {
    flex-direction: column !important;
  }
  @media screen and (max-width : 900px) {
    width:  99vw;
  }
}


::-webkit-scrollbar {
  height: 0px; /* height of horizontal scrollbar */
  width: 2px;
  @media screen and (max-width: 900px){
  display: none;    
  }
}
body {
  overflow: overlay;
}
::-webkit-scrollbar-thumb {
  background: #ABB0B9;
  opacity: 0.5;
  border-radius: 3px;
}
.fileContainer .uploadPictureContainer {
  width: 100% !important;
}
.confirmDelete {
margin: .5rem;
}
.deleteButton {
background-color: red !important;
&:hover {
  background-color: rgb(201, 0, 0) !important;
  border: red !important;
}
}


.clip_loader_spinner_container {
  width: 100%;
  height: 70%;
  align-items: center;
  justify-content: center;
  // backdrop-filter: blur(8px);
  z-index: 999999;
}

// canvas style 
.canvasDraw {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
.canvasDrawViewer {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important;
}
.relative {
  position: relative !important;
}
.canvasButtons {
  position:absolute;
   top:30px;
   right: 0px;
   display:flex ; 
   justify-content: space-evenly; 
   align-items:center; 
   width:30%; 
   margin-bottom:.5rem; 
   height:30px; 
   background:#00000050;
   opacity: 0;
   box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),0 8px 16px -8px rgba(0, 0, 0, 0.3);
   transition: opacity .2s;

}
.canvasButton {
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}
.canvasButton.disable {
  color: #00000050;
  cursor: auto;
}
.form-collection {
  &:hover .canvasButtons {
    opacity: 1 !important;
    transition: opacity .2s;
  }
}

// grapes style
.grapesJsContainer {
  position: fixed;
  top: 0px;
  z-index: 3;
  height: 100vh;
  width: 100vw;
  min-width: 400px;
  background: rgb(244, 239, 243);
  overflow: auto;
}
.grapesInnerContainer {
  width: 100vw;
  height: 100vw;
}

@keyframes toggleSideBar {
  0% {
      display: none;
      opacity: 0;
  }

  1% {
      display: block;
      opacity: 0;
  }

  75% {
      display: block;
      opacity: 1;
  }
  100% {
    display: block;
    opacity: 0;
}
}