// base colors
$black: #212121;
$white: #fff;
$lighterGray: #fafafa;
$lightGray: #fefefe;
$gray: #e0e0e0;
$darkGray: #616161;
$primaryBlue: #017a8f;
$hoverBlue: #049bb6;
$red: #ff0000;
$hoverRed: #c74c46;
$mainDark: #191f2b;
$lightDark: #222a37;
$main: #fff;
$subMain: #F7FBFE;
$accent: #B20312;
$subAccent: #CF1C3C;
$pink: #F84173;
$sky: #6064D2;
$blue: #464883;


// font weights
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$heavy: 800;

// fonts
$code: 'Rubik', sans-serif;

$sidebarWidth: 16vw;
$sidebarWidth-mobile: 0vw;
$sidebarMinWidth: 200px;
$sidebarMinWidth-mobile: 0px;
$headerHeight: 8%;
$screenPadding: 0 1rem;
