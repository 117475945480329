@import "./base.scss";

.modal {
  width: 40vw;
  min-width: 300px;
  background: white;
  border: 1px solid $gray;
  animation: fade-in 0.25s;
  transform: scale(1);
  padding: 1rem;
  visibility: visible;
  z-index: 99999;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  overflow: auto;
  .header {
    min-height: 10px;
    margin-bottom: 1rem;
  }
  @media screen and (max-width:1100px) { 
    width: 80vw !important;
    
  }
  @media screen and (max-width:700px) { 
    width: 100vw !important;
    
  }
}
.task-modal {
  @media screen and (max-width:1100px) { 
    width: 80vw !important;
    
  }
  @media screen and (max-width:700px) { 
    width: 100vw !important;
    
  }
}
.min-w {
  min-width: 700px !important;
}
.widget-body {
  margin-left: 2rem;
  font-weight: 500;
  font-size: .9rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  user-select: none;
  color: $black;
}

@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.close-modal {
  color: $black;
  font-size: 18px;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
}

.form-group-container {
  width: 100%;
}

.form-label {
  width: 100%;
  padding-right: 2rem;
  margin-bottom: 0.75rem;
  font-size: 14px;
  color: $white;
}

.created-by {
  font-size: 15px;
}
.form-dropdown {
  background-color: $lighterGray !important;
  border: 1px solid $lighterGray !important;
  border-radius: 8px !important;
  height: 40px !important;
  line-height: 1.5em !important;
  outline: 0 !important;
  transition: all 0.25s ease 0s !important;
  font-size: 16px !important;
}
.form-input {
  background-color: $lighterGray;
  border: 1px solid $lighterGray;
  border-radius: 8px !important;
  height: 40px !important;
  width: 100% !important;
  line-height: 1.5em !important;
  outline: 0 !important;
  transition: all 0.25s ease 0s !important;
  font-size: 16px !important;
  padding: 0 1rem !important;
min-width: 240px;
  &:hover {
    border-color: $gray;
    transition: all 0.25s ease 0s;
  }

  &:focus {
    border-color: $primaryBlue;
    transition: all 0.25s ease 0s;
  }
}

.split {
  display: flex;
  justify-content: space-between;
}

.split-input {
  width: 47.5%;
}

.split-email {
  padding-right: 0rem;
}

.task-input-split {
  width: 165px;
}

.month-due {
  margin-right: 1rem;
}

.members {
  margin-top: 1rem;
  height: 170px;
  padding: 5px;
  overflow-x: scroll;
  border-bottom: 1px solid $gray;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgb(23, 100, 201);
  }
  
}


.members-edit {
  margin-top: 1rem;
  overflow-y: scroll;
  border-bottom: 1px solid $gray;
  height: 130px;
  padding: 5px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgb(23, 100, 201);
  }
}


.add-members {
  background-color: $white;
  color: $primaryBlue;

  &:hover {
    color: $white;
  }
}

.create-project,
.update-project,
.delete-project {
  position: fixed;
  bottom: 1.25rem;
}

.delete-project {
  margin-left: 335px;
  background-color: $red;
  border: 1px solid $red;

  &:hover {
    background-color: $red;
    border: 1px solid $red;
    opacity: 0.9;
  }
}

.delete {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  color: $black;
  font-size: 0.5rem;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
    color: $red;
  }
}

.Widget-cont {
  width: 100%;
  height: 350px;
  padding: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgb(23, 100, 201);
  }
  
}
.widget-card{
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  padding-top: 40px;
  box-shadow: 0 0 .3rem rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  cursor: pointer;
  
}
.widget-icon {
  font-size: 39px !important;
}
