
@import "../../../assests/styles/base.scss";


.modal-wrapper {
    display: flex;
    justify-content: center;
  }
  
  .task-modal {
    height: 100vh;
  }
  
.auth-button {
    height: 40px;
    margin-top: 1rem;
    color: #ffffff;
    background-color: #3D80C2;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 16px;
    transition: all 0.25s ease 0s;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  
    &:hover {
      background-color: $hoverBlue;
      border-color: $hoverBlue;
      cursor: pointer;
      transition: all 0.25s ease 0s;
    }
  }
  .sideSearchh {
    position: relative;
    height: 40px;
    min-height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    margin: .2rem .2rem;
    font-weight: $bold;
    font-size: 14px;
    font-family: $code;
    border-left: 3px solid transparent;
    border-radius: 8px;

    &:hover {
        background: rgba(171, 176, 185, 0.2);
border-radius: 8px;
    }
}
  
.addUser {
    margin-top: 0rem !important;
    width: 140px;
    height: 32px;
    min-height: 32px;
    font-size: 12px;

}
  .disable-button {
    background-color: #3D80C269;
      border-color: transparent;
      transform: none;
      cursor: auto;
      transition: none;
      opacity: .5 !important;
    &:hover {
      background-color: #3D80C269;
      border-color: transparent;
      transform: none;
      cursor: auto;
      transition: none;
    }
  }
  
  .password-modal {
    height: 50vh;
    width: 25vw;
    display: flex;
    flex-direction: column;
    padding: 3rem 3rem;
    align-items: center;
    justify-content: space-between;
    .header {
      padding: 0px;
    }
    .form-collection {
      width: 100% !important;
    }
    .auth-button {
      width: 100% !important;
    }
  }
  .task-labelColor {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    height: 2vh;
  }
  
  .task-form {
    display: flex;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  
  .form-group-task {
    display: flex !important;
    width: 80% !important;
  
  }
  .editImage {
    position: relative;
    &:hover {
      .editImageIcon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .editImageIcon {
    background-color: rgba(255, 255, 255, 0.534);
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
    cursor: pointer;
  }
  .column-form {
    flex-direction: column !important;
  }
  .form-collection {
    margin: 5px;
    width: 50%;
  }
  .form-row {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .textarea {
    width: 50%;
  }
  .header-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .textarea-input {
    width: 100% !important;
    height: 120px !important;
    padding: 1rem 1rem;
  }
  