.createdSelect {
    border: 1px solid lightblue;
    height: 40px;
    border-radius: 8px;
    margin: 20px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width:"20%";
    min-width: 200px;

}