@import "../../../assests/styles/base.scss";

.base-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: $white;
  background-repeat: no-repeat;
  background-size: contain;
}

.privacy {
  position: absolute;
  bottom: 10px;
  left: 3rem;
  font-size: 14px;
  line-height: 140%;
  color: #5E6A7A;
  margin-bottom: 1rem;
}
.orContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.or {
  height: 0px;
  border-bottom: 1px solid #ABB0B9;
  width: 45%;
  border-radius: 8px;
}
.auth-header {
  color: $black;
  text-align: left;
  margin-bottom: .5rem;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  letter-spacing: 0.5px;
}
.loginHeader {
  
font-style: normal;
font-weight: 500;
font-size: 38px;
line-height: 56px;
letter-spacing: 2px;
text-transform: capitalize;
color: #FFFFFF;
position: absolute;
bottom: 100px;

}
.errspan {
  display: block;
  float: right;
  margin-right: 10px;
  margin-top: -34px;
  position: relative;
  z-index: 2;
  color: #C5C5C5;
  font-size: 20px;
  cursor: pointer;
}

.demo {
  font-size: 14px;
  margin-top: -0.25rem;
  text-align: center;
  width: 300px;
  line-height: 1.25rem;
}

.code-mail {

  &:hover {
    cursor: pointer;
    color: $primaryBlue;
  }
}

.auth-form {
  width: 480px;
  background-color: $white;
  border: 1px solid $gray;
  padding: 2rem;
}

.auth-group {
  margin-bottom: 1.5rem;
}

.underMenu {
  top: -203px !important ;
}

.auth-label {
  width: 100%;
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  font-size: 14px;
  color: $black;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logoContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem;
}
.Dropdown-menu {
  top: 115% !important;
  border-radius: 8px;
  padding: 20px 8px !important; 
  }

.Dropdown-option {
  &.is-selected {
    background: rgba(171, 176, 185, 0.2) !important;
  }
margin: 10px 0px;
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 4px 8px;
position: static;
height: 32px;
left: 8px;
top: 20px;
border-radius: 8px;

&:hover {
background: rgba(171, 176, 185, 0.2) !important;
/* Inside auto layout */
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}
}
.auth-input {
  display: flex;
  align-items: center;
  background-color: $white;
  border: 1px solid #ABB0B9;
  border-radius: 8px !important;
  height: 48px !important;
  width: 100%;
  line-height: 1.5em;
  outline: 0;
  transition: all 0.25s ease 0s;
  font-size: 14px;
  padding: 0 1rem;
  .Dropdown-placeholder {
    color: #ABB0B9;
    &.is-selected {
      color: #222831;
    }
  }
  &:hover {
    .Dropdown-placeholder {
      &.is-selected {
        color: #222831;
      }
         color: #5E6A7A;
        transition: all 0.25s ease 0s;
    }
    border: 1px solid #5E6A7A;
    transition: all 0.25s ease 0s;
    &::placeholder {
      color: #5E6A7A;
      transition: all 0.25s ease 0s;
    }
  }
  &::placeholder {
    color: #ABB0B9;
  }

  &:focus {
    border-color: #6064D2;
    transition: all 0.25s ease 0s;
  }
}
.hoverS {
  &:hover {
background-color: #ABB0B9 !important;
color: white !important;
  }
}
.Dropdown-arrow-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  .arrowI {
    display: flex;
    align-items: center;
    height: 100%;
  }
}
.Dropdown-root.is-open {
  .auth-input {
  border-color: #6064D2 !important; 
  }
}
.underMenu {
  top: -203px !important ;
}
.auth-input-bar {
  background-color: $white;
  border-radius: 8px;
  border: 0px;
  height: 100%;
  width: 100%;
  line-height: 1.5em;
  outline: 0;
  transition: all 0.25s ease 0s;
  font-size: 16px;
  &:hover {
    border-color: $gray;
    transition: all 0.25s ease 0s;
  }

  &:focus {
    border-color: $primaryBlue;
    transition: all 0.25s ease 0s;
  }
}
.doneText {
  font-weight: normal;
font-size: 15px;
line-height: 140%;
/* or 20px */


/* Black */

color: #222831;


/* Inside auto layout */ 

flex: none;
order: 0;
flex-grow: 0;
margin: 24px 0px;
}
.auth-error {
  color: $accent;
  font-size: 14px;
}
.auth-error-border {
  border: 1px solid $accent !important;
}

.auth-button {
  height: 48px;
  margin-top: 1rem;
  color: #ffffff;
  background-color: $accent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 16px;
  transition: all 0.25s ease 0s;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 400;

  &:hover {
    background-color: $subAccent;
    border-color: $subAccent;
    cursor: pointer;
    transition: all 0.25s ease 0s;
  }
}
.hoverButton {
  &:hover {
      background-color: $subAccent !important;
  border-color: $subAccent !important;
  cursor: pointer;
  transition: all 0.25s ease 0s;
  }

}

.disable-button {
  background-color: #3D80C269;
    border-color: transparent;
    transform: none;
    cursor: auto;
    transition: none;
    opacity: .5 !important;
  &:hover {
    background-color: #3D80C269;
    border-color: transparent;
    transform: none;
    cursor: auto;
    transition: none;
  }
}

.bottom-group {
  display: flex;
  justify-content: flex-end;
}

.link {
  color: #6064D2;
  text-decoration: none;
  transition: all 0.25s ease 0s;
  cursor: pointer;
  font-size: 12px;

  &:hover {
    color: #4548aa;
  }
}


.signinPageContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: #F0F2FA;
  overflow: auto;
  background-color:  $sky  !important;
  background-size: cover;

}
.singinPageContent {
   background-color: $sky !important;
   background-size: cover;
}
.rightSignInContainer {
  width: 80%;
  display: flex;
  justify-content: center;
  @media screen and (max-width:777px) {
    width: 0px;
    display: none;
    
  }
}
.signinContainer {
  width: 67%;
  min-width: 200px;  
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  
background: white;
backdrop-filter: blur(20px);
  @media screen and (max-width:777px) {
    width: 100%;
    
  }
  
}
.logoContentContainer {
  height: 50%;
  display: flex;

}
.logoText {
  font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 35px;
letter-spacing: 0.5px;
width: 50%;
color: #FFFFFF;
@media screen and (max-width:996px) {
  width: 80%;
  
}
}
.signinLogo {
  height: 30%;
  width: 100%;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.signinContent {
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0 2rem;
}
.bottom-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.rememberMeContainer {
  color: $darkGray;
  font-size: 14px;
}
label {
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #ABB0B9;
  font-weight: 400;
  cursor: pointer !important;
  &:hover {
    color: #5E6A7A;
    font-weight: 500;
    cursor: pointer !important;
    transition: all 0.25s ease 0s;
    span {
      border: 2px solid #5E6A7A;
      transition: all 0.25s ease 0s;
    }
  }

}
label input {
  display: none; /* Hide the default checkbox */
}

/* Style the artificial checkbox */
label span {
  height: 18px;
  width: 18px;
  border: 2px solid #ABB0B9;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  margin-right: .7rem;
  margin-left: .5rem;
}
.spanChecked {
  background-color: $accent;
  border: 2px solid $accent;
}

/* Style its checked state...with a ticked icon */
[type=checkbox]:checked + span:before {
  content: '\2714';
  color: white;
  position: absolute;
  top: -3px;
  font-size: 15px;
  left: 0;
}
.signinBoxContainer {
  width: 70%;
}


@media (max-width: 550px) {
  .base-wrapper {
    background-size: cover;
  }
}
